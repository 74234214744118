import React from 'react'
import styled from 'styled-components'
// import TheodoraLoves from '../components/theodora_loves'
// import CarouselLarge from '../components/carousel_large'
import HomepageCarousel from '../components/homepage_carousel'
import Gallery from '../components/gallery'
// import Config from '../config'
import Home from '../components/home'
import SEO from '../components/seo'
// import Scene from '../components/interactive_necklace';
// import CarouselGemstones from '../components/carousel_gemstones'
// import Carousel_Gemstones_Mobile from '../components/carousel_gemstones_mobile';
import DownArrow from '../images/arrows/down2.svg'
import background from '../images/backgrounds/home.jpg'
import useWindowSize from '@rehooks/window-size';

const Anchor = styled.a`
  position: absolute;
  top: 100vh;
  @media (max-width: 768px) {
    top: calc(100vh - 100px);
  }
`

const Announcement = styled.div`
  position: absolute;
  top: 26px;
  width: 100%;
  text-align: center;
  @media (max-width: 768px) {
    top: 130px;
    padding-left: 50px;
    padding-right: 50px;
  }
  p {
    font-style: italic;
    font-size: 17px;
  }
`

const BackgroundContainer = styled.div`
  margin-bottom: 30px;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url(${background});
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    max-height: calc(100vh);
    min-height: 0;
    height: calc(100vh - 100px);
    margin-bottom: 0px;
  }
`
const DownArrowContainer = styled.div`
  text-align: center;
  padding-bottom: 50px;
  transform: translateY(-40px);
  @media (max-width: 768px) {
    z-index: 99999999;
    transform: translateY(-120px);
    padding-bottom: 0px;
  }
`

const IndexPage = () => {

  let width;
  let windowSize;
  if(typeof window !== 'undefined') {
    windowSize = useWindowSize();
    width = windowSize.innerWidth;
  } else {
    width = 1280;
  }

  return(
    <div>
      <SEO title="Theodora Warre - Home" keywords={[`jewellery`, `Theodora`, `Warre`]} />
      <Home />
    </div>
  )
}

export default IndexPage
