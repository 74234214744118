import React, { useState } from 'react';
import Carousel from 'nuka-carousel';
import styled from 'styled-components'

import ProductEmerald from '../../images/emerald.png'
import ProductPositivity from '../../images/positivity.png'
import ProductRuby from '../../images/ruby.png'

const Container = styled.div`
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  @media (max-width: 768px) {
    min-height: 80vh;
  }
`

const Slide = styled.div`
  width: 100%;
  min-height: 60vh;
  background-repeat: no-repeat;
  background-image: url(${props => props.background});
  background-size: contain;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: default;
  @media (max-width: 768px) {
    min-height: 80vh;
  }
  h3 {
    text-align: center;
    font-size: 11px;
    line-height: 14px;
    transform: translateY(-100px);
    max-width: 80%;
    font-weight: 400;
    padding-bottom: 0px;
    a {
      color: #000;
      text-decoration: none;
    }
  }
`

const HomepageCarousel = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  return (
    <Container>
      <Carousel
        slidesToShow={1}
        wrapAround={true}
        swiping={true}
        dragging={true}
        renderBottomCenterControls={null}
        enableKeyboardControls={true}
        autoplayInterval={1000}
        speed={3000}
        autoplay={true}
        transitionMode="fade"
        renderCenterLeftControls={null}
        renderCenterRightControls={null}
      >
      <Slide background={ProductPositivity}><h3><a href="https://theodorawarre.co.uk/positivity-earrings"></a></h3></Slide>
      <Slide background={ProductEmerald}><h3><a href="https://theodorawarre.co.uk/emerald-hoop-earrings"></a></h3></Slide>
      <Slide background={ProductRuby}><h3><a href="https://theodorawarre.co.uk/ruby-hoop-earrings"></a></h3></Slide>

      <Slide background={ProductPositivity}><h3><a href="https://theodorawarre.co.uk/positivity-earrings"></a></h3></Slide>
      <Slide background={ProductEmerald}><h3><a href="https://theodorawarre.co.uk/emerald-hoop-earrings"></a></h3></Slide>
      <Slide background={ProductRuby}><h3><a href="https://theodorawarre.co.uk/ruby-hoop-earrings"></a></h3></Slide>

      <Slide background={ProductPositivity}><h3><a href="https://theodorawarre.co.uk/positivity-earrings"></a></h3></Slide>
      <Slide background={ProductEmerald}><h3><a href="https://theodorawarre.co.uk/emerald-hoop-earrings"></a></h3></Slide>
      <Slide background={ProductRuby}><h3><a href="https://theodorawarre.co.uk/ruby-hoop-earrings"></a></h3></Slide>

      <Slide background={ProductPositivity}><h3><a href="https://theodorawarre.co.uk/positivity-earrings"></a></h3></Slide>
      <Slide background={ProductEmerald}><h3><a href="https://theodorawarre.co.uk/emerald-hoop-earrings"></a></h3></Slide>
      <Slide background={ProductRuby}><h3><a href="https://theodorawarre.co.uk/ruby-hoop-earrings"></a></h3></Slide>

    </Carousel>
  </Container>
  )
}

export default HomepageCarousel
