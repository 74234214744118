import React from 'react'
import styled from 'styled-components'
import HomeBG from '../../images/home.jpg'

const Container = styled.div`
  min-height: calc(100vh - 48px);
  position: relative;
  img {
    width: 100%;
    min-height: calc(100vh - 48px);
    object-fit: cover;
    object-position: center center;
  }
`

const Block = styled.a`
  position: absolute;
  display: block;
  top: ${props => props.y};
  left: ${props => props.x};
  width: ${props => props.w};
  height: ${props => props.h};
  z-index: 2;
  text-decoration: none;
  @media (max-width: 768px) {
    width: 18%;
  }
`

const Home = () => {
  return (
    <Container>
      <Block href="/gypsy-hoop-earrings" x="48%" y="42%" w="11%" h="17%" />
      <img src={HomeBG} />
    </Container>
  )
}

export default Home
